<template>
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="video-wrapper">
            <div class="video-container">
              <iframe
                id="youtube-player"
                width="100%"
                height="100%"
                :src="videoSrc"
                title="Hamochi Kids Official Trailer"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <v-btn small class="ma-4 pt-1" @click="exit" width="100%" max-width="100">{{$t("action.skip")}} <v-icon class="mb-1">mdi-skip-next</v-icon></v-btn>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    tutorial: (state) => state.tutorial.data,
  }),
  data() {
    return {
      videoSrc:
        "https://www.youtube.com/embed/i7_rzQqrQ8s?autoplay=1&enablejsapi=1",
      player: null,
    };
  },
  created(){
    let tempSettings;
    if (this.settings) {
      tempSettings = this.$_.cloneDeep(this.settings);
      tempSettings["login_at"] = this.$moment().format();
    } else {
      tempSettings = {
        login_at: this.$moment().format(),
      };
    }
    this.$store.commit("updateSettings", tempSettings);
  },
  mounted() {
    this.loadYouTubeAPI();
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    loadYouTubeAPI() {
      // Check if API is already loaded
      if (window.YT && window.YT.Player) {
        this.initializePlayer();
      } else {
        const script = document.createElement("script");
        script.src = "https://www.youtube.com/iframe_api";
        script.async = true;
        script.onload = () => {
          window.onYouTubeIframeAPIReady = this.initializePlayer;
        };
        document.head.appendChild(script);
      }
    },
    initializePlayer() {
      this.player = new window.YT.Player("youtube-player", {
        events: {
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    onPlayerStateChange(event) {
      if (event.data === window.YT.PlayerState.ENDED) {
        this.exit();
      }
    },
  },
};
</script>

<style scoped>
.video-wrapper {
  width: 100%;
  padding-top: 100%; /* 1:1 aspect ratio */
  position: relative;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-body {
  background-color: rgb(0, 0, 0);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
